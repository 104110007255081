// extracted by mini-css-extract-plugin
export var desc = "ProcessSlide-module--desc--tI0Yc";
export var slideContainer = "ProcessSlide-module--slideContainer--6yirq";
export var contentContainer = "ProcessSlide-module--contentContainer--LjdXU";
export var tagContainer = "ProcessSlide-module--tagContainer--Ocvv7";
export var number = "ProcessSlide-module--number--ldbuj";
export var title = "ProcessSlide-module--title--nH73I";
export var value = "ProcessSlide-module--value--t5QRE";
export var valueContainer = "ProcessSlide-module--valueContainer--mA0Iv";
export var valueImg = "ProcessSlide-module--valueImg--0iC-m";
export var numbering = "ProcessSlide-module--numbering--TvpTc";