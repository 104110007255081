import React from "react";
import Heading from "../../components/Heading/Heading";
import ProcessCard from "../../components/ProcessCard/ProcessCard";
import {
  diffTrackContainer,
  processCardContainer,
  firstCard,
} from "./DifferentTracks.module.css";

export default function DifferentTracks() {
  const list = [""];
  return (
    <div className={diffTrackContainer}>
      <Heading hashtag="" start="different" middle="tracks" end="" />
      <div className={processCardContainer}>
        <ProcessCard
          title="Pan India Track"
          desc="The pan India track invites participation from exciting startups across the country. The startups hence undergo various tiers of shortlisting and mentoring and are provided with ample networking opportunities. "
          icon="https://user-images.githubusercontent.com/69129797/153991808-2f0b6170-e6c4-4323-827b-e8f7e873ea3b.png"
          link=""
          linkText=""
        />
        <ProcessCard
          title="Sustainibility Track"
          desc="Sustainibility Track provides opportunity to network and get mentored by prominent industry experts within the domain. The registration can be done by the common application"
          icon="https://user-images.githubusercontent.com/69129797/153991814-8656821f-3d23-4766-9deb-8c6d87e7a973.png"
          link="https://docs.google.com/document/d/1pll8f7OfQbpruWvQtD-ue5NwhLGo3BAuabOOKrJOPUM/edit"
          linkText="Rules for BITSian Track"
        />
      </div>
    </div>
  );
}
