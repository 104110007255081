import React from "react";

import {
  heroContainer,
  heroContentContainer,
  learnMore,
  arrow,
} from "../Hero/Hero.module.css";
import Heading from "../../components/Heading/Heading";
import {
  heroIllustration,
  heroDesc,
  heroTitle,
  heroIllustrationContainer,
  joinButton,
  ctaContainer,
  heroProcess,
} from "./ProcessHero.module.css";
import { Link } from "gatsby";

export default function ProcessHero() {
  return (
    <div className={heroContainer}>
      <div className={heroContentContainer}>
        <Heading
          className={heroTitle}
          hashtag=""
          start="look how we put things"
          middle="together"
          end=""
        />
        <p className={heroDesc}>
          Conquest is India’s first student run startup accelerator which
          provides mentorship to the best of the best startups of India by
          connecting them with stalwarts in the field. After a rigorous process
          of selection, the top 10 are given the opportunity to pitch before the
          biggest investment firms of the industry in Bangalore.
        </p>
        <div className={ctaContainer}>
          {/* <a
            href="https://form.jotform.com/220931435484456"
            target="_blank"
            rel="noreferrer"
          >
            <button className={joinButton}>REGISTER NOW</button>
          </a> */}
        </div>
      </div>
      <div className={heroIllustrationContainer}>
        <img
          className={heroIllustration}
          src="https://user-images.githubusercontent.com/69129797/155760857-c14d4a8a-74c7-4f49-83d1-1d286dfada2f.png"
          alt=""
        ></img>
      </div>
    </div>
  );
}
