import React from "react";
import {
  numbering,
  contentContainer,
  desc,
  tagContainer,
  slideContainer,
  number,
  title,
  value,
  valueContainer,
  valueImg,
} from "./ProcessSlide.module.css";

const Processslide = (props) => {
  return (
    <div className={slideContainer}>
      <div className={numbering}>
        <h1 className={number}>{props.number}</h1>
        <p className={title}>{props.title}</p>
      </div>
      <div className={contentContainer}>
        {/* <p className={desc}>{props.desc}</p>
        <div className={tagContainer}>
          {props.tags.map((item) => {
            return <h3>{item}</h3>;
          })}
        </div> */}
        <h1 className={value}>Prospective Value</h1>
        <div className={valueContainer}>
          <p>{props.propsDesc}</p>
          <img src={props.img} className={valueImg} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default Processslide;
