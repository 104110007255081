import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import "./global.css";
import ProcessHero from "../sections/ProcessHero/ProcessHero";
import Timeline from "../sections/Timeline/Timeline";
import RegBanner from "../sections/RegBanner/RegBanner";
import DifferentTracks from "../sections/DifferentTracks/DifferentTracks";
import OurProcess from "../sections/OurProcess/OurProcess";
import "../fonts/stylesheet.css";

// styles
const text = {
  fontFamily: "Manrope",
  cursor: "url('../images/logo.png')",
};

// markup
const ProcessPage = () => {
  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <ProcessHero />
      <Timeline />
      <DifferentTracks />
      <OurProcess />
      <RegBanner />
      <Footer />
    </main>
  );
};

export default ProcessPage;
