import React from "react";
import {
  cardContainer,
  cardTitleContainer,
  cardTitleContent,
  cardIcon,
  cardIconContainer,
  purpose,
  purposeDesc,
  listItem,
} from "./ProcessCard.module.css";

import { alumniLink, arrow } from "../../sections/WhoWeWorkWith/WhoWeWorkWith.module.css";
import icon from "../../images/process/india.png";

export default function ProcessCard(props) {
  return (
    <div className={cardContainer}>
      <div className={cardTitleContainer}>
        <div className={cardTitleContent}>
          <h1>{props.title}</h1>
          <p>{props.desc}</p>
        </div>
        <div className={cardIconContainer}>
          <img className={cardIcon} src={props.icon} alt=""></img>
        </div>
      </div>
      <a href={props.link} target="_blank">
        <div className={alumniLink}>
          <p>{props.linkText}</p>
        </div>
      </a>
    </div>
  );
}
