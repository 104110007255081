import * as React from "react";
import Heading from "../../components/Heading/Heading";
import ProcessSlide from "../../components/ProcessSlide/ProcessSlide";
import {
  ourProcessContainer,
  processExplain,
  ourProcessSlidesContainer,
  restrict,
} from "./OurProcess.module.css";
import { Stepper } from "@progress/kendo-react-layout";

const icons = [
  {
    label: "Mentoring",
    icon: "k-i-circle",
  },
  {
    label: "Fireside Chats",
    icon: "k-i-circle",
  },
  {
    label: "Pitching",
    icon: "k-i-circle",
  },
  {
    label: "Workshops",
    icon: "k-i-circle",
  },
  {
    label: "Demo Day",
    icon: "k-i-circle",
  },
];

export default function OurProcess() {
  const style = {
    textAlign: "center",
  };
  const headingStyle = {
    margin: "5",
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (e) => {
    setValue(e.value);
    console.log(e.value);
    document.querySelector(
      ".OurProcess-module--ourProcessSlidesContainer--QgwcB"
    ).style.transform = `translateX(-${e.value * 100}vw)`;
    // .style.transform = `translateX(-${e.value * 100}vw)`;
  };

  const tags = [""];

  return (
    <div className={ourProcessContainer}>
      <Heading
        start="our"
        middle="process"
        end=""
        hashtag=""
        style={style}
        headingStyle={headingStyle}
      />
      <p className={processExplain}>
        Our startups undergo various stages of shortlisting, each step aimed to
        bring out the best in them. Have a look at our process.
      </p>
      <Stepper value={value} onChange={handleChange} items={icons} />
      <div className={restrict}>
        <div className={ourProcessSlidesContainer}>
          <ProcessSlide
            number="1."
            title="Mentoring Session"
            desc=" "
            tags={tags}
            propsDesc="With various tiers of shortlisting, our top-30 cohort receives mentoring from industry experts over various domains such as product, growth, marketing, design and UI/UX."
            img="https://i.imgur.com/VBCYh1h.jpg"
          />
          <ProcessSlide
            number="2."
            title="Fireside Chats"
            desc="  "
            tags={tags}
            propsDesc="To deliver the best value to our startups, we organize fireside chats, which are specifically tailored sessions catering to specific sectors, hence offering a space for startups to get real-life advice from the stalwarts themselves."
            img="https://i.imgur.com/gv5KzgB.jpg"
          />
          <ProcessSlide
            number="3."
            title="Pitching Sessions"
            desc="  "
            tags={tags}
            propsDesc="Pitching sessions are organized with representatives from our investment partners to provide an investor’s viewpoint to our startups and help them perfect their pitch."
            img="https://i.imgur.com/HIbgg6j.jpg"
          />
          <ProcessSlide
            number="4."
            title="Workshops"
            desc="  "
            tags={tags}
            propsDesc="Investment workshops are held with our investment partners for our startups to get in-depth insights on topics such as fundraising, pitching and branding."
            img="https://i.imgur.com/VOqibr4.jpg"
          />
          <ProcessSlide
            number="5."
            title="Demo Day"
            desc="  "
            tags={tags}
            propsDesc="The Demo Day witnesses our startups pitching to a decorated audience of investors, jury members, angel investors and industry experts. The winner hence receives an equity-less cash prize."
            img="https://i.imgur.com/dJaWtLx.jpg"
          />
        </div>
      </div>
    </div>
  );
}
